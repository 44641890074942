import React from "react";
import { a } from "@react-spring/web";

export default function Overlay({ fill }) {
  return (
    <div className="overlay">
      <a.svg
        viewBox="0 0 583 720"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="#E8B059" d="M47.5 53.5h9v9h-9zM40" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61 40H50.5v9H61v10.5h9V40h-9z"
          fill="#E8B059"
        />
        <text
          style={{ whiteSpace: "pre" }}
          fontFamily="Inter"
          fontSize={6}
          fontWeight="bold"
          letterSpacing="-.02em"
        >
          <tspan x={392} y={46.182} children="ARITIFICAL" />
          <tspan x={392} y={54.182} children="INTELLIGENCE" />
          <tspan x={392} y={62.182} children="STUDIO" />
        </text>
        <text
          style={{ whiteSpace: "pre" }}
          fontFamily="Inter"
          fontSize={10.5}
          fontWeight={500}
          letterSpacing="0em"
        >
          <tspan x={40} y={175.318} children="ADVANCING" />
          <tspan x={40} y={188.318} children="HUMAN-AI INTERACTION" />
        </text>
        <text
          fill="#E8B059"
          style={{ whiteSpace: "pre" }}
          fontFamily="Inter"
          fontSize={32}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan
            x={40}
            y={257.909}
            children={"CONVERSATIONAL AI FOR EVERYONE"}
          />
        </text>
        <text
          style={{ whiteSpace: "pre" }}
          fontFamily="Inter"
          fontSize={42}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={40} y={321.909} children="We are building" />
          <tspan x={40} y={372.909} children="easy-to-use AI tools" />
          <tspan x={40} y={423.909} children="that learn from you," />
          <tspan x={40} y={474.909} children="grow with you," />
          <tspan x={40} y={525.909} children="and help you" />
          <tspan x={40} y={575.909} children="do more every day;" />
        </text>
        <text
          style={{ whiteSpace: "pre" }}
          fontFamily="Inter"
          fontSize={10.5}
          fontWeight={500}
          letterSpacing="0em"
        >
          <tspan
            x={326}
            y={640.318}
            children="© 2023 Cybernity Inc. | hello@cybernity.ai"
          />
        </text>
      </a.svg>
    </div>
  );
}
